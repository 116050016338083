@import '../variables.module.css';
@import './config.module.css';

.paper {
  composes: reset from '../helpers.module.css';

  border-radius: var(--paper-border-radius);
  background: var(--paper-background);
  border-width: 1px;
  margin: 0 0 1.25rem;
  padding: 1.25rem
}

.paper.depth1 {
    box-shadow: var(--zdepth-shadow-1)
  }

.paper.depth2 {
    box-shadow: var(--zdepth-shadow-2)
  }

.paper.depth3 {
    box-shadow: var(--zdepth-shadow-3)
  }

.paper.depth4 {
    box-shadow: var(--zdepth-shadow-4)
  }

.paper.depth5 {
    box-shadow: var(--zdepth-shadow-5)
  }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .paper {
    border-top: 1px solid #f1f1f1;
    border-left: 1px solid #f1f1f1;
  }
}
