.add {
  cursor: pointer;
  color: var(--color-meh);
  user-select: none;
  font-size: 0.8rem;
  margin: 0.5rem 0 0 1rem;

  & > * {
    vertical-align: middle;
  }

  & > i {
    color: var(--color-info);
    font-size: 1.2rem;
  }
}

.alertChannel {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.alertChannelName {
  cursor: default;

  & > * {
    vertical-align: middle;
  }

  & > i {
    color: var(--color-info);
    margin-right: 0.5rem;
  }
}


.alert:hover {
  background: rgba(0,0,0, 0.05);
}

.actions {
  & > a > i, & > i, & > button i {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 0.25rem;
  }
}

.edit {
  color: var(--color-info);
}

.delete {
  color: var(--color-danger);
}

.on {
  width: 100%;
  margin-top: 0.5rem;
}
