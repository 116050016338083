.message {
  font-size: 1.2rem;
}

.admins {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.slackImage {
  height: 1.25rem;
  margin-right: 0.25rem;
  vertical-align: middle;
}