.title {
  color: var(--color-text);
  font-size: 1.1rem;
  text-decoration: none;
}

.envs {
  width: 40%;
}

.sparkline {
  width: 100%;
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .title {
    flex: 1;
    font-size: 1.5rem;
    font-weight: 500;

    & .tenant {
      text-transform: uppercase;
    }
  }

  & > .search {
    flex: 1;
  }

  & > .addProject {
    margin-left: 4rem;
  }
}

.envLabel {
  display: block;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin: 1rem 0 0.5rem;
  user-select: none;
}

.col {
  padding-bottom: 1rem;
}

.project {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wrap {
  margin-top: 0;
}
