/* Orientation */

/* Devices (defined by max width) */

/* Devices (defined by min-width) */

:root {
  --standard-increment-mobile: calc(5.6 * var(--unit));
  --standard-increment-desktop: calc(6.4 * var(--unit));
}
