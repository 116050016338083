.wrapper {
  display: flex;
  position: fixed;
  padding-top: 25vh;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-index-higher);
  background: rgba(0,0,0,0.2);
}

.content {
  padding: 0.75rem;
  width: 50vw;
  background: rgba(0,0,0,0.3);
  border-radius: 0.5rem;
  position: relative;
}

.message {
  color: var(--color-white);
}

.kbd {
  border: 1px solid var(--color-gray-4);
  border-radius: 2px;
  padding: 0.2em 0.5em;
  background-color: var(--color-gray-6);
}

.table {
  & tr td {
    padding-top: 0.8rem !important;
    padding-bottom: 0.8rem !important;
  }
}

.shortcut {
  width: 20%;
}

.card {
  overflow: hidden;
}
