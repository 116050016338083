.actions {
  margin-top: 1rem;

  & > button + button {
    margin-left: 0.5rem;
  }
}

.checkbox {
  margin-bottom: 0;
}

.readonly {
  opacity: 0.65;
}
