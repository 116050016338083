.group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--color-gray-3);

  & > .name {
    font-size: 1.1rem;

    & > a {
      color: var(--color-text);
      text-decoration: none;
      word-break: break-all;
    }

    & > * {
      vertical-align: middle;
    }

    & > i {
      color: var(--color-gray-3);
      margin-right: 1.25rem;
    }
  }
}

.chip {
  background: #666666;
  color: #fff;
  padding: 0.25rem 0.45rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
  font-weight: bold;
  margin-left: 0.75rem;
}

.heading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.loadMore {
  text-align: center;
}
