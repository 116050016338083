@import '../../media.module.css';
@import './config.module.css';

.navWrap {
  width: 100%;
  background: var(--nav-background);
  color: var(--nav-text-color)
}

.navWrap.underhang {
    position: relative
  }

.navWrap.underhang:after {
      display: block;
      height: 4rem;
      background: var(--nav-background);
      content: '';
      top: 5.5rem;
      position: absolute;
      width: 100%;
      z-index: -1;
    }

.navWrap > .nav {
    box-sizing: border-box;
    display: flex;
    margin: 0 auto;
    width: 1200px;
    justify-content: space-between
  }

.navWrap > .nav > .left {
      display: flex;
      align-items: center;
      height: 5.5rem
    }

.navWrap > .nav > .left.fluid {
        width: 100%
      }

.navWrap > .nav > .left.fluid > .logo {
          padding-left: 2rem;
        }

.navWrap > .nav > .left > .logo {
        margin: 0 2rem 0 0;
        color: var(--nav-logo-text-color);
      }

.navWrap > .nav > .right {
      display: flex;
      align-items: center;
    }

.navWrap > .nav > .left > ul, .navWrap > .nav > .right > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex
    }

.navWrap > .nav > .left > ul + ul, .navWrap > .nav > .right > ul + ul {
        margin-left: auto
      }

.navWrap > .nav > .left > ul + ul + .rightSide, .navWrap > .nav > .right > ul + ul + .rightSide {
          margin-left: 1.5rem;
        }

.navWrap > .nav > .left > ul > li, .navWrap > .nav > .right > ul > li {
        margin: 0 0.25rem
      }

.navWrap > .nav > .left > ul > li > a, .navWrap > .nav > .right > ul > li > a {
          display: inline-block;
          text-decoration: none;
          color: var(--nav-text-color);
          padding: 0.8em 1.5em;
          border-radius: 0.25rem
        }

.navWrap > .nav > .left > ul > li > a:hover, .navWrap > .nav > .right > ul > li > a:hover {
            color: var(--nav-link-active-text-color);
          }

.navWrap > .nav > .left > ul > li.active > a, .navWrap > .nav > .right > ul > li.active > a {
            background: var(--nav-link-active-background);
            color: var(--nav-link-active-text-color);
          }

@media screen and (max-width: 1280px) {
  .navWrap > .nav {
    width: 100%;
    padding: 0 2rem;
  }
}

@media screen and (max-width: 960px) {
    .navWrap > .nav > .left > ul > li > a,
    .navWrap > .nav > .right > ul > li > a {
      font-size: 0.8rem;
    }

    .navWrap > .nav > .right > ul {
      display: none;
      width: 0;
    }
}
