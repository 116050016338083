.input {
  padding:  0 0 0.75rem !important;
}

.input > input {
  font-size: 0.7rem !important;
  line-height: 1;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.form {
  & > label {
    text-transform: uppercase;
  }
}
