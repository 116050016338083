.details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bar {
  background: #f7f7f7;
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: var(--card-border-radius) var(--card-border-radius) 0 0;

  & > .name {
    flex: 1;

    & > small {
      font-weight: 100;
      color: var(--color-meh);
    }

    & > h2 {
      margin: 0;
    }
  }

  & > button > i {
    margin-right: 0.3rem;
    font-size: 1.1rem;
    vertical-align: text-top;
  }
}

.actions {
  flex-shrink: 0;

  & button + button {
    margin-left: 0.75rem;
  }
}

.description {
  width: 100%;
  color: var(--color-meh);
  margin: 0.4rem 6px 1rem;
  font-size: 0.9rem;
}

.actionsMenu {
  position: relative;
  font-size: 34px;
  margin-right: 1rem;
  vertical-align: middle;
}

.actionsDropdown {
  width: 11rem;
}

@media (prefers-color-scheme: dark) {
  .bar {
    background: var(--color-gray-4);
  }
}
