.logoIcon,
.logoText {
  vertical-align: middle;
}

.logoIcon {
  width: 40px;
}

.logoText {
  font-size: 1.2rem;
}

.logoLink {
  display: inline-block;
  color: inherit;
  text-decoration: none;

  &:visited,
  &:hover,
  &:focus {
    color: inherit;
  }
}

.rightSide {
  display: flex;
  align-items: center;
}

.devTools {
  border: var(--color-warning) 1px dashed;
  margin-right: 1rem;
  padding: 0.3rem 0.5rem 0;
  position: relative;

  &:before {
    content: "Dev Actions";
    position: absolute;
    font-size: 10px;
    top: -14px;
    left: 0;
    width: 100%;
    text-align: center;
    color: var(--color-text-contrast);
  }

  & > i {
    cursor: pointer;
    & + i {
      margin-left: 1rem;
    }
  }
}

.navWrap {
  margin-bottom: 0.5rem;
}
