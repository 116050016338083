.pagination {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  & > div {
    background: var(--color-gray-5);
    border-radius: 0.2rem;
    padding: 0.2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .pagination {
    & > div {
      background: var(--color-gray-4);
    }
  }
}
