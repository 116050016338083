.envVars {
  margin-bottom: 1rem;
}

.sensitiveWrap {
  display: flex;
  align-items: center;
}

.sensitiveIcon {
  margin-right: 5px;
}
