.error {
  color: var(--color-danger);

  margin-right: 0.5rem;

  & > span,
  & > i {
    vertical-align: middle;
  }

  & > span {
    font-size: 0.8rem;
  }

  & > i {
    font-size: 1.2rem;
    margin-right: 0.25rem;
  }
}

.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
