.tabNavigation {
  background: var(--color-gray-5);
  box-shadow: inset 0 -1px var(--color-gray-3);
}

.tabLabel {
  color: var(--color-text);
}

.tabPointer {
  background: var(--color-denim);
}

.pageHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  display: flex;

  & > button:not(:only-child):first-child {
    margin-right: 1rem;
  }
}

@media (prefers-color-scheme: dark) {
  .tabNavigation {
    background: var(--color-gray-4);
  }
}
