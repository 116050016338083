:root {


  --color-divider: var(--color-gray-1);
  --color-background: var(--color-white);
  --color-text-secondary: var(--color-denim);

  --color-primary: var(--color-white);
  --color-primary-dark: var(--color-denim);
  --color-accent: var(--color-gray-2);
  --color-accent-dark: var(--color-gray-6);
  --color-primary-contrast: rgb(255, 255, 255);
  --color-accent-contrast: rgb(255, 255, 255);

  --unit: 10px;

  --primary-font: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  --secondary-font: 'Ubuntu', 'Helvetica', 'Arial', sans-serif;
  --font-size: calc(1.6 * var(--unit));
  --font-size-tiny: calc(1.2 * var(--unit));
  --font-size-small: calc(1.4 * var(--unit));
  --font-size-normal: var(--font-size);
  --font-size-big: calc(1.8 * var(--unit));
  --font-weight-thin: 300;
  --font-weight-normal: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  --shadow-2p: 0 4px 10px 0px rgba(38, 54, 82, 0.5);
  --shadow-2p-up: 0 -4px 10px 0px rgba(38, 54, 82, 0.5);

  --shadow-3p:
    0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);

  --shadow-4p:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);

  --shadow-6p:
    0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);

  --shadow-8p:
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2);

  --shadow-16p:
    0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(0, 0, 0, 0.2);

  --shadow-key-umbra-opacity: 0.2;
  --shadow-key-penumbra-opacity: 0.14;
  --shadow-ambient-shadow-opacity: 0.12;

  --zdepth-shadow-1: 0 4px 8px 0px rgba(38, 54, 82, 0.1);
  --zdepth-shadow-2: 0 4px 3px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
  --zdepth-shadow-3: 0 10px 30px rgba(0, 0, 0, 0.19), 0 6px 10px rgba(0, 0, 0, 0.23);
  --zdepth-shadow-4: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
  --zdepth-shadow-5: 0 19px 60px rgba(0, 0, 0, 0.3), 0 15px 20px rgba(0, 0, 0, 0.22);

  --animation-duration: 0.35s;
  --animation-delay: calc(var(--animation-duration) / 5);
  --animation-curve-fast-out-slow-in: cubic-bezier(0.4, 0, 0.2, 1);
  --animation-curve-linear-out-slow-in: cubic-bezier(0, 0, 0.2, 1);
  --animation-curve-fast-out-linear-in: cubic-bezier(0.4, 0, 1, 1);
  --animation-curve-default: var(--animation-curve-fast-out-slow-in);

  --default-transition: var(--animation-duration) var(--animation-curve-default);

  --z-index-highest: 300;
  --z-index-higher: 200;
  --z-index-high: 100;
  --z-index-normal: 1;
  --z-index-low: -100;
  --z-index-lower: -200;
}

@media (prefers-color-scheme: dark) {
  :root {
    --shadow-2p: 0 4px 10px 0px rgba(0, 0, 0, 0.5);
    --shadow-2p-up: 0 -4px 10px 0px rgba(0, 0, 0, 0.5);
  }
}
