
div.tooltip {
  max-width: 500px;
  text-transform: none;
  background-color: #F8F8F8 !important;
  color: var(--color-meh) !important;
  border: 1px solid #E0E0E0;
  text-align: center;
  font-weight: bold;
}
