.projects {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: rgba(0,0,0, 0.05);
    }
  }
}

.noProjects {
  text-align: center;

  & > button {
    display: block;
    margin: 0.5rem auto;
  }
}
