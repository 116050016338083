.container {
  text-align: center;
  margin: 20% auto;
}

.container img {
  margin: 0 0.625rem;
  font-size: 3rem;
  color: black;
  transition: color ease-out;
  animation: roll 2s ease-in-out infinite, colors 7s linear alternate infinite;
  width: 24px;
}

.i1 {
  animation-delay: .35s !important;
}

.i2 {
  animation-delay: .75s !important;
}

.i3 {
  animation-delay: 1.05s !important;
}

@keyframes colors {
  0% {
    color: black;
    transition: color ease-out;
  }
  25% {
    color: rgb(204, 56, 56);
    transition: color ease-out;
  }
  50% {
    color: rgb(36, 108, 243);
    transition: color ease-out;
  }
  75% {
    color: rgb(48, 184, 48);
    transition: color ease-out;
  }
}

@keyframes roll {
  from {
    transform: rotateZ(0);
  }

   to {
    transform: rotateZ(360deg);
  }
}
