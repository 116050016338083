@import '../colors.module.css';
@import '../variables.module.css';
@import '../media.module.css';
@import './config.module.css';

.wrapper {
  align-items: center;
  composes: reset from '../helpers.module.css';
  display: flex;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: var(--z-index-higher);
}

.dialog {
  background: var(--dialog-background);
  border-radius: var(--dialog-border-radius);
  box-shadow: var(--zdepth-shadow-modal);
  display: flex;
  flex-direction: column;
  max-height: var(--dialog-max-height);
  width: 100%;
  max-width: 1000px;
  margin: 0 100px;
  opacity: 0;
  transform: translateY(calc(-1 * var(--dialog-translate-y)));
  transition: opacity var(--animation-duration) var(--animation-curve-default),
              transform var(--animation-duration) var(--animation-curve-default);
  transition-delay: var(--animation-delay)
}

.dialog.active {
    opacity: 1;
    transform: translateY(0%);
  }

.small {
  width: 30vw
}

@media screen and (max-width: 720px) {

.small {
    width: 50vw
}
  }

@media screen and (max-width: 600px) {

.small {
    width: 75vw
}
  }

.normal {
  width: 1000px
}

@media screen and (max-width: 600px) {

.normal {
    width: 96vw
}
  }

.large {
  width: 96vw;
}

.fullscreen {
  width: 96vw
}

@media screen and (max-width: 600px) {

.fullscreen {
    border-radius: 0;
    max-height: 100vh;
    max-width: 100vw;
    min-height: 100vh;
    width: 100vw
}
  }

.title {
  color: var(--dialog-color-title);
  font-family: var(--primary-font);
  font-size: calc(2 * var(--unit));
  font-weight: var(--font-weight-normal);
  margin: 0 0 calc(1.6 * var(--unit));
}

.body {
  color: var(--color-text-secondary);
  flex-grow: 2;
  padding: var(--dialog-content-padding) var(--dialog-content-padding) var(--dialog-content-bottom-padding);
  overflow-y: auto;
}

.navigation {
  flex-grow: 0;
  padding: 0 var(--dialog-navigation-padding) var(--dialog-navigation-padding);
  text-align: right;
}

.button {
  margin-left: 1rem;
}

.hideBackButton {
  display: none;
}

.backButton {
  display: inline-block;
  cursor: pointer;
  z-index: 2;
  margin: 0;
}

.top {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width:62em) {
  .dialog {
    height: calc(100% - 50px);
    max-height: 100%;
    width: 100%;
    margin: 25px;
    align-items: stretch;
  }
  .navigation {
    text-align: center;
  }

  .button {
    width: 100%;
    max-width: 100%;
  }
}
