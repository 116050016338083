.selectText {
  color: var(--color-label);
  text-align: center;
}

.delete {
  font-size: 0.8rem;

  border-bottom: 1px solid var(--color-danger);
  text-align: center;
  color: var(--color-meh);
  background: rgba(208, 0, 26, 0.1);
}

.deleteButton {
  padding: 0.4rem 0;

  & > i {
    font-size: 1.1rem;
  }
}

.configurationTypes {
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    height: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background: var(--color-gray-5);
    }
  }
}
