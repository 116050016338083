:root {
    --checkbox-color: var(--color-denim);
    --checkbox-border-color: var(--color-gray-4);
    --checkbox-border-color-hover: var(--color-denim);
    --checkbox-disabled-color: var(--color-gray-1);
    --checkbox-disabled-border-color: var(--color-gray-2);
    --checkbox-field-margin-bottom: calc(1.5 * var(--unit));
    --checkbox-focus-checked-color: rgba(18, 112, 203, 0.26);
    --checkbox-ripple-duration: 650ms;
    --checkbox-size: calc(2.2 * var(--unit));
    --checkbox-focus-color: rgba(44, 185, 255, 0.1);
    --checkbox-focus-size: calc(var(--checkbox-size) * 2.3);
    --checkbox-text-color: var(--color-text);
    --checkbox-text-font-size: var(--font-size-small);
    --checkbox-total-height: calc(2.2 * var(--unit));
    --checkbox-transition-duration: 0.2s;
}

@media (prefers-color-scheme: dark) {
    :root {
        --checkbox-border-color: var(--color-gray-1);
    }
}
