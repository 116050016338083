.changes {
  padding-left: 1rem;
}

.pagination {
  display: flex;
  justify-content: flex-end;
}

.paginationContainer {
  border-radius: 0.2rem;
  padding: 0.2rem;
  display: inline-block;
}

.log {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem;

  & > li {
    display: flex;
    justify-content: center;

    & > div {
      padding-top: 1rem;
    }

    & > .leftSide {
      width: 25%;
      padding-right: 1.5rem;
      text-align: right;

      & > .userName {
        font-size: 1.25rem;

        & > .userType {
          color: var(--color-meh);
          padding: 1px 2px;
          margin-left: 5px;

          & > i {
            font-size: 20px;
          }
        }
      }

      & > .time {
        color: var(--color-meh);
      }
    }

    & > .details {
      display: flex;
      flex-wrap: wrap;
      width: 75%;

      & .snapshot {
        flex-basis: 100%;
        background: var(--color-gray-6);
        padding: 0.5rem;
      }

      & > div {
        flex-basis: 20%;
        padding-bottom: 1rem;

        & > label {
          text-transform: uppercase;
          color: var(--color-meh);
          display: block;
          font-size: 0.8rem;
          letter-spacing: 0.05rem;
          margin-bottom: 0.5rem;
          user-select: none;
        }
      }
    }
  }
}
