.head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nameCol {
  width: 100%;
}

.loading {
  animation: spin 2s infinite linear;
}

.noClusters {
}

.resync {
  vertical-align: middle;
  margin-left: 0.5rem;
  font-size: 1rem;
}

@keyframes spin {
  from {
    transform: rotateZ(0);
  }

  to {
    transform: rotateZ(-360deg);
  }
}


/* Add these to your existing style.module.css file */

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nameCol {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resync {
  margin-left: 8px;
  font-size: 18px;
  vertical-align: middle;
}

.loading {
  animation: spin 1s linear infinite;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.loadingContainer p {
  margin-top: 16px;
  color: #666;
}

.spinnerContainer {
  display: flex;
  align-items: center;
}

.buttonText {
  margin-right: 8px;
}

.buttonSpinner {
  display: inline-block;
  color: white !important;
  margin-left: 4px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}