:root {
  --radio-border-color: var(--color-gray-4);
  --radio-field-margin-bottom: calc(1.5 * var(--unit));
  --radio-button-size: calc(2 * var(--unit));
  --radio-inner-margin: calc(var(--radio-button-size) / 4);
  --radio-inner-color: var(--color-denim);
  --radio-focus-color: rgba(18, 112, 203, 0.1);
  --radio-checked-focus-color: rgba(18, 112, 203, 0.26);
  --radio-text-color: var(--color-text);
  --radio-text-font-size: calc(1.4 * var(--unit));
}

@media (prefers-color-scheme: dark) {
  :root {
    --radio-border-color: var(--color-gray-1);
  }
}
