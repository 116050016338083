.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none
}
.breadcrumbs > li.active {
      color: #6c757d;
    }
.breadcrumbs > li > a {
      color: #007AB8;
      text-decoration: none;
    }
.breadcrumbs > li + li {
      padding-left: .5rem
    }
.breadcrumbs > li + li:before {
        display: inline-block;
        padding-right: .5rem;
        color: #6c757d;
        content: "/";
      }
