:root {
  --menu-expand-duration: 0.3s;
  --menu-fade-duration: 0.2s;
  --menu-background: var(--color-white);
  --menu-padding: calc(0.8 * var(--unit)) 0;
  --menu-outline-border-radius: calc(0.2 * var(--unit));
  --menu-item-selected-background: transparent;
  --menu-item-icon-font-size: calc(2.4 * var(--unit));
  --menu-item-icon-size: calc(1.6 * var(--menu-item-icon-font-size));
  --menu-item-height: calc(4.8 * var(--unit));
  --menu-item-padding: calc(1.6 * var(--unit));
  --menu-item-font-size: calc(1.4 * var(--unit));
  --menu-divider-height: calc((4.8 / 4) * var(--unit));
  --menu-icon-size: calc(2.3 * var(--unit));
  --menu-item-color: var(--color-text);
  --menu-item-background-hover: var(--color-gray-2)
}

@media (prefers-color-scheme: dark) {
  :root {
    --menu-background: var(--color-gray-3);
  }
}
