@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.list {
  composes: reset from '../helpers.module.css';
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: var(--list-vertical-padding) 0;
  position: relative;
  text-align: left;
  white-space: nowrap;
  width: 100%;
}
.subheader {
  color: var(--color-text-secondary);
  font-size: var(--list-subheader-font-size);
  font-weight: var(--list-subheader-font-weight);
  line-height: var(--list-subheader-height);
  margin: calc(-1 * var(--list-vertical-padding)) 0 0;
  padding-left: var(--list-horizontal-padding);
}
.divider {
  background-color: var(--color-gray-3);
  border: 0;
  height: var(--list-divider-height);
  margin: 0
}
.divider.inset {
    margin-left: var(--list-content-left-spacing);
    margin-right: var(--list-horizontal-padding);
  }
.listItem {
  position: relative
}
.listItem > [data-react-toolbox='ripple'] {
    overflow: hidden;
  }
.listItem .ripple {
    color: var(--color-text-secondary);
  }
.listItem ~ .divider {
    margin-bottom: var(--list-vertical-padding);
    margin-top: var(--list-vertical-padding);
  }
.item {
  align-items: center;
  color: var(--color-text);
  display: flex;
  min-height: var(--list-item-min-height);
  padding: 0 var(--list-horizontal-padding);
  position: relative
}
.item.selectable:not(.disabled):hover,
  .item.selected {
    background-color: var(--list-item-hover-color);
    cursor: pointer
  }
.item.selectable:not(.disabled):hover .itemText, .item.selected .itemText {
      color: var(--color-white);
    }
.item.disabled {
    pointer-events: none
  }
.item.disabled:not(.checkboxItem) {
      opacity: 0.5;
    }
.item.disabled > .checkbox > [data-react-toolbox='label'] {
      opacity: 0.5;
    }
.left [data-react-toolbox='font-icon'] {
    width: var(--list-item-icon-size);
  }
.left :last-child > [data-react-toolbox='font-icon'] {
      margin-right: var(--list-item-right-icon-margin);
    }
.right > :last-child {
    margin-right: 0;
  }
.right > :first-child {
    margin-left: var(--list-horizontal-padding);
  }
.left,
.right {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
}
.itemAction {
  display: flex;
  margin: var(--list-item-child-margin) var(--list-horizontal-padding) var(--list-item-child-margin) 0
}
.itemAction > * {
    padding: 0;
  }
.itemAction > [data-react-toolbox='font-icon'] {
    color: var(--color-text-secondary);
    font-size: var(--list-item-icon-font-size);
  }
.itemContentRoot {
  display: block;
  flex-grow: 1
}
.itemContentRoot.large {
    display: flex;
    flex-direction: column;
    height: var(--list-item-min-height-legend);
    justify-content: center;
  }
.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 100%;
  margin: 0;
  min-height: var(--list-item-min-height);
  width: 100%
}
.checkbox > [data-react-toolbox='check'] {
    margin-right: var(--list-item-right-checkbox-margin);
  }
.checkbox > [data-react-toolbox='label'] {
    padding-left: 0;
  }
.itemText {
  display: block
}
.itemText.primary,
  .itemText:not(.primary) {
    color: var(--color-gray-6);
    text-transform: uppercase;
    font-family: var(--secondary-font);
  }
.itemText:not(.primary) {
    font-size: calc(1.3 * var(--unit));
    white-space: normal;
  }
.itemText.primary {
    font-size: calc(1.6 * var(--unit));
    line-height: 1.5;
    letter-spacing: 0.8px;
  }
