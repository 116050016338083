.wrapper {
  display: flex;
  position: fixed;
  padding-top: 25vh;
  justify-content: center;
  align-items: flex-start;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--z-index-higher);
}

.active {
  opacity: 0 !important;
}
