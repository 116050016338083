@import './config.module.css';
@import '../input/config.module.css';

:global(.select__value-container) {
  padding: var(--multi-select-field-padding) !important;
}

:global(.select__control--is-focused) {
  box-shadow: 0 0 0 1px #cccccc !important;
  border: var(--input-border) !important;
}

:global(.select__control) {
  background: var(--input-text-background-color) !important;
  color: var(--color-text) !important;
  border: var(--input-border) !important;
}

:global(.select__menu) {
  background: var(--color-gray-6) !important;
}

:global(.select__option--is-focused) {
  background: var(--color-gray-5) !important;
}

:global(.select__input) {
  color: var(--color-text) !important;
}
