.prompt {
  color: var(--color-meh);
  font-style: italic;
  text-align: center;
}

.radios {
  color: var(--color-text);
  background: var(--color-gray-6);
  border: 1px solid var(--color-gray-3);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding:  0.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  flex-wrap: wrap;

  & > label {
    margin: 0.5rem 0;

    & > span {
      position: initial;
      max-width: initial;
    }
  }
}

@media (prefers-color-scheme: dark) {
  .radios {
    background: var(--color-gray-4);
  }
}
