:root {
    --input-padding: calc(2 * var(--unit));
    --input-border: 1px solid var(--color-gray-4);
    --input-field-padding: calc(0.8 * var(--unit));
    --input-field-font-size: calc(1.6 * var(--unit));
    --input-field-height: calc(var(--input-field-padding) * 2 + var(--input-field-font-size) * 1.4);
    --input-label-font-size: calc(1.3 * var(--unit));
    --input-focus-label-top: calc(.2 * var(--unit));
    --input-text-background-color: rgb(250, 250, 250);
    --input-text-label-color: var(--color-text);
    --input-text-bottom-border-color: rgba(0, 0, 0, 0.12);
    --input-text-highlight-color: var(--color-rhino);
    --input-text-disabled-color: var(--input-text-bottom-border-color);
    --input-text-disabled-text-color: var(--input-text-label-color);
    --input-text-error-color: var(--color-danger);
    --input-text-required-color: var(--color-danger);
    --input-underline-height: calc(2.5 * var(--unit));
    --input-icon-font-size: 1.5rem;
    --input-icon-size: calc(2 * var(--input-icon-font-size));
    --input-icon-offset: calc(1.6 * var(--unit));
    --input-icon-right-space: calc(2 * var(--unit));
    --input-chevron-offset: calc(0.8 * var(--unit));
    --input-hint-opacity: 1;
}

@media (prefers-color-scheme: dark) {
    :root {
        --input-text-background-color: var(--color-gray-3);
        --input-text-highlight-color: var(--color-text);
    }
}
