@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.tabs {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  composes: reset from '../helpers.module.css';
}

.navigation {
  box-shadow: inset 0 -1px var(--tab-navigation-border-color);
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  position: relative;
}

.navigationContainer {
  display: flex;
  height: 100%;
  min-height: 50px;
  align-items: center
}

.navigationContainer .navigation {
    flex: 1;
  }

.arrow {
  color: var(--tab-text-color);
  padding: 0 var(--tab-label-h-padding);
}

.arrowContainer {
  align-items: center;
  box-shadow: inset 0 -1px var(--tab-navigation-border-color);
  cursor: pointer;
  display: flex;
}

.label {
  font-family: var(--primary-font);
  font-weight: var(--font-weight-thin);
  letter-spacing: 1px;
  font-size: calc(var(--unit) * 1.2);
  color: var(--color-white);
  line-height: 1.5;
  padding: var(--tab-label-v-padding) var(--tab-label-h-padding);
  position: relative;
  text-transform: uppercase;
  transition-duration: var(--animation-duration);
  transition-property: background-color, box-shadow, color;
  transition-timing-function: var(--animation-curve-default)
}

.label > .animatedText {
    display: inline-block;
    position: relative
  }

.label > .animatedText::before {
      background: rgba(85, 195, 249, 0.33);
      bottom: 0;
      content: '';
      height: 20px;
      position: absolute;
      transform: scaleX(0) scaleY(0.4);
      transform-origin: 0 100%;
      transition: transform 0.3s;
      width: 100%;
    }

.label > .animatedText:hover {
      transition: linear 0.2s;
      opacity: 1
    }

.label > .animatedText:hover::before {
          transform: scaleX(1) scaleY(0.4);
        }

.label:focus {
    outline: none;
  }

.label > .rippleWrapper {
    overflow: hidden;
  }

.label.disabled {
    opacity: var(--tab-label-disabled-opacity);
  }

.label:not(.disabled) {
    cursor: pointer;
  }

.label.hidden {
    display: none;
  }

.label.withIcon {
    padding-bottom: calc(var(--tab-label-v-padding) - var(--tab-icon-margin-bottom) / 2);
    padding-top: calc(var(--tab-label-v-padding) - var(--tab-icon-margin-bottom) / 2);
    text-align: center;
  }

.label.withText .icon {
      margin-bottom: var(--tab-icon-margin-bottom);
    }

.icon {
  display: block;
  height: var(--tab-icon-height);
  line-height: var(--tab-icon-height);
  margin: 0 auto;
}

.pointer {
  background-color: var(--tab-pointer-color);
  height: var(--tab-pointer-height);
  margin-top: calc(-1 * var(--tab-pointer-height));
  position: absolute;
  transition-duration: var(--animation-duration);
  transition-property: left, width;
  transition-timing-function: var(--animation-curve-default);
  width: 0;
}

.hover {
  background-color: var(--tab-pointer-color);
  height: var(--tab-pointer-height);
  margin-top: calc(-1 * var(--tab-pointer-height));
  position: absolute;
  transition-duration: var(--animation-duration);
  transition-property: left, width;
  transition-timing-function: var(--animation-curve-default);
  width: 0;
}


.disableAnimation {
  display: none;
}

.tab {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: var(--tab-label-v-padding) var(--tab-label-h-padding);
  position: relative
}

.tab:not(.active) {
    display: none;
  }

.tab.active {
    display: flex;
  }

.fixed .label {
    text-align: center;
  }

.inverse .navigation,
  .inverse .arrowContainer {
    background-color: #364d75;
  }

.inverse .label {
    color: var(--tab-inverse-text-inactive-color)
  }

.inverse .label.active {
      color: var(--tab-inverse-text-color);
    }

.inverse .arrow {
    color: var(--tab-inverse-text-color);
  }

.inverse .pointer {
    background-color: var(--tab-inverse-pointer-color);
  }

