@import './config.module.css';

.card {
  background: var(--card-background);
  border: var(--card-border);
  border-radius: var(--card-border-radius)
}

.card > .head {
    border-bottom: var(--card-border);
    padding: 1rem;
    font-size: var(--card-head-font-size);
    font-weight: var(--card-head-font-weight);
  }

.card > .content {
    padding: 1rem;
  }

.card > .foot {
    border-top: var(--card-border);
    padding: 1rem;
  }

.card:not(:last-child) {
    margin-bottom: 1rem;
  }
