.link {
  color: var(--color-text);
  border-bottom: 1px dotted var(--color-text);
  text-decoration: none;

  & > i {
    font-size: 1rem;
    vertical-align: top;
    padding-left: 0.2rem;
  }
}
