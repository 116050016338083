.container {
  padding: 0.75rem;
  margin: 1rem 0;
  composes: box from '~style/helpers.module.css';
}

.heading {
  margin-bottom: 1rem;
}

.add {
  cursor: pointer;
  color: var(--color-meh);
  user-select: none;
  font-size: 0.8rem;
  margin: 0.5rem 0 0 1rem;

  & > * {
    vertical-align: middle;
  }

  & > i {
    color: var(--color-info);
    font-size: 1.2rem;
  }
}

.hookUrlWrap {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hookUrl {
  cursor: default;
  margin-bottom: 0.5rem;

  & > * {
    vertical-align: middle;
  }

  & > i {
    color: var(--color-info);
    margin-right: 0.5rem;
  }
}


.hook:hover {
  background: rgba(0,0,0, 0.05);
}

.actions {
  & > a > i, & > i, & > button i {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 0.25rem;
  }
}

.edit {
  color: var(--color-info);
}

.delete {
  color: var(--color-danger);
}

.on {
  width: 100%;
}

.input {
  padding:  0 0 0.75rem !important;
}

.input > input {
  font-size: 0.7rem !important;
  line-height: 1;
}

.formActions {
  display: flex;
  justify-content: space-between;
}

.form {
  & > label {
    text-transform: uppercase;
  }
}

.keyAlert {
  width: 100%;
}

.signingKeyWrap {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .key {
    overflow: hidden;
    margin-right: 0.5rem;

    & code {
      background: var(--chip-background);
      padding: 0.25rem;
      border: 1px solid var(--chip-border);
      border-radius: 0.25rem;
      white-space: nowrap;
      display: block;
      overflow-x: scroll;
    }
  }
}
.radioFieldset {
  border: none;
  margin: 0;
  padding: 0;

  & > legend {
    font-size: 13px;
    font-weight: 500;
  }
}

.radios {
  color: var(--color-text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  flex-wrap: wrap;

  & > label {
    margin-top: 1rem;
    margin-right: 1rem;
    text-transform: initial;

    & > span {
      position: initial;
      max-width: initial;
      text-transform: none;
    }
  }
}
