.title {
  display: inline-block;
  color: var(--color-text);
  font-size: 1.2rem;
  text-decoration: none;
  margin-bottom: 0.4rem;
}

.envs {
  width: 40%;
}

.sparkline {
  & path:first-child {
    fill: var(--color-rhino) !important;
  }
  & path:last-child {
    stroke: var(--color-rhino) !important;
  }
}

.head {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button + button {
    margin-left: 0.5rem;
  }

  & > .title {
    flex: 1;
    font-size: 1.5rem;
    font-weight: 500;

    & .tenant {
      text-transform: uppercase;
    }
  }

  & > .search {
    flex: 1;
  }

  & > .addProject {
    margin-left: 4rem;
  }
}

.envLabel {
  display: block;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin: 1rem 0 0.5rem;
  user-select: none;
}

.col {
  padding-bottom: 1rem;
}

.project {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.noProjects {
  font-size: 1.5rem;
  text-align: center;
  flex: 1;
  color: var(--color-meh);
  margin: 3rem 0rem 3rem 0rem;
}

.projectsHeading {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.heading {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

@media (prefers-color-scheme: dark) {
  .sparkline {
    & path:first-child {
      fill: var(--color-dodger-blue) !important;
    }
    & path:last-child {
      stroke: var(--color-dodger-blue) !important;
    }
  }
}
