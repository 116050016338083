:root {
    --nav-background: var(--color-rhino);
    --nav-text-color: var(--color-peri-gray);
    --nav-logo-text-color: var(--color-white);
    --nav-link-active-text-color: var(--color-white);
    --nav-link-active-background: var(--color-big-stone);
}

@media (prefers-color-scheme: dark) {
    :root {
        --nav-background: var(--color-gray-6);
        --nav-link-active-background: var(--color-gray-5);
        --nav-text-color: var(--color-gray-1);
    }
}
