.releaseNotes {
  margin-right: 15px;
  cursor: pointer;
  position: relative;
}

.notify:before {
  content: '';
  height: .125rem;
  width: .125rem;
  border: .25rem solid red;
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  background: #fff;
  display: block;
}
