:root {
  --paper-background: var(--color-white);
  --paper-border-radius: 0.3125rem;
}

@media (prefers-color-scheme: dark) {
  :root {
    --paper-background: var(--color-gray-4);
  }
}
