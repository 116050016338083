.log {
  padding: 0.4rem 0.6rem;
  background: #212121;
  display: block;
  overflow-y: scroll;
  max-height: 20rem;

  & > code {
    font-family: monospace;
    display: block;
    color: var(--color-text-contrast);

    &.header { font-weight: bold; }

    & .time { color: var(--color-label); }

    & .info { color: var(--color-dodger-blue); }
    & .warn { color: var(--color-warning)}
    & .error { color: #fe4861 }
  }
}
