.badges {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.badge {
  display: inline-flex;
  align-items: center;
}

.badge img {
  max-height: 24px;
  max-width: 120px;
  width: auto;
  height: auto;
  object-fit: contain;
}

.addBadgeContainer {
  margin-bottom: 12px;
  display: flex;
}

.badgesContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 6px;
  gap: 8px;
}

.badgeItem {
  display: flex;
  align-items: center;
}
