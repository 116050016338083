@import '../variables.module.css';
@import '../colors.module.css';

.wrap {
  position: fixed;
  bottom: 15%;
  right: 10px;
  z-index: 150;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--color-slate);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  text-align: center;
  line-height: 40px;
  color: var(--color-white);
  cursor: pointer;
}

.support {
  font-family: var(--secondary-font);
  font-size: 25px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
}
