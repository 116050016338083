.content {
  display: inline-block;

  & > code {
    padding: 1px;
    background: var(--color-gray-6);
  }

  & > i {
    cursor: pointer;
    padding-left: 1rem;
    color: var(--color-info);
    vertical-align: middle;
  }
}
