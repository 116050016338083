@import './variables.module.css';

.reset {
  box-sizing: border-box;
  font-family: var(--primary-font);
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
      -ms-text-size-adjust: 100%;
          text-size-adjust: 100%
}

.reset *,
  .reset *::after,
  .reset *::before {
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
       -moz-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
            text-size-adjust: 100%;
  }
