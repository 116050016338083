@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.container:not(:last-child) {
    margin-bottom: 1rem;
  }

.input {
  composes: reset from '../helpers.module.css';
}

.inputElementWrap {
  position: relative
}

.inputElementWrap > .icon {
    color: var(--input-text-label-color);
    display: block;
    font-size: var(--input-icon-font-size) !important;
    height: var(--input-icon-size);
    line-height: var(--input-icon-size) !important;
    position: absolute;
    text-align: center;
    top: 1px;
    transition: color var(--animation-duration) var(--animation-curve-default);
    width: var(--input-icon-size);
  }

.inputElementWrap.withIconLeft > .inputElement {
      padding-left: 2.75rem;
    }

.inputElementWrap.withIconLeft > .icon {
      left: 0;
    }

.inputElementWrap.withIconRight > .inputElement {
      padding-right: 2.75rem;
    }

.inputElementWrap.withIconRight > .icon {
      right: 0;
    }

.inputElement {
  cursor: inherit;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--color-text);
  background-color: var(--input-text-background-color);
  border: var(--input-border);
  border-radius: 4px;
  display: block;
  outline: none;
  padding: var(--input-field-padding);
  width: 100%
}

.inputElement:focus:not([disabled]):not([readonly]) ~ .label > .required {
      color: var(--input-text-required-color);
    }

.inputElement:focus:not([disabled]):not([readonly]) ~ .icon {
      color: var(--input-text-highlight-color);
    }

.inputElement:focus:not([disabled]):not([readonly]) ~ .label:not(.fixed), .inputElement.filled ~ .label:not(.fixed), .inputElement[type='date'] ~ .label:not(.fixed), .inputElement[type='time'] ~ .label:not(.fixed) {
      font-size: var(--input-label-font-size);
      top: var(--input-focus-label-top);
    }

.label {
  pointer-events: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 0.5em;
  color: var(--input-text-label-color);
}

.error,
.counter,
.hint {
  color: var(--input-text-error-color);
  font-size: var(--input-label-font-size);
  line-height: var(--input-underline-height);
  margin-bottom: calc(-1 * var(--input-underline-height));
  margin-top: 5px;
}

.hint {
  color: var(--input-text-label-color);
}

.counter {
  color: var(--input-text-label-color);
  position: absolute;
  right: 0;
}

.disabled > .inputElement {
  background: #f7f9fa;
  color: #cccccc;
  border: solid 1px #ececf0;
}

.errored {
  padding-bottom: 0
}

.errored > .inputElement {
    border-color: var(--input-text-error-color);
    margin-top: 1px;
  }

.errored > .counter,
  .errored > .label {
    color: var(--input-text-error-color);
  }

.errored > .label > .required {
    color: var(--input-text-required-color);
  }

.hidden {
  display: none;
}
