:root {
  --card-border-radius: 0.5rem;
  --card-head-font-size: 1.2rem;
  --card-head-font-weight: 500;
  --card-background: var(--color-white);
  --card-border: 1px solid var(--color-gray-3);
}

@media (prefers-color-scheme: dark) {
  :root {
    --card-background: var(--color-gray-5);
  }
}
