.wrap {
  width: 50%;
  margin: 0 auto;
  padding: 3rem;
  display: flex;
  align-items: center;
  z-index: 1;

  & .icon {
    & i {
      user-select: none;
      font-size: 12rem;
      opacity: 0.2;
    }
  }

  & .text {
    flex: 1;

    & > .title {
      font-size: 4rem;
      margin-bottom: 0.5rem;
      border-bottom: 2px solid var(--color-danger);
    }
  }
}
