.box {
  box-shadow: var(--shadow-light);
  background: #fff;
}

.sidebarLabel {
  text-transform: uppercase;
  color: var(--color-label);
  display: block;
  font-size: 0.8rem;
  letter-spacing: 0.05rem;
  margin-bottom: 0.5rem;
  user-select: none;
}

@media (prefers-color-scheme: dark) {
  .box {
    background: var(--color-gray-4);
  }
}
