.tagSelector {
  flex: 1 1 1rem;
  overflow-y: auto;
}

.current {
  background: var(--color-success);
  border-radius: 0.2rem;
  padding: 0.1rem 0.25rem;
  font-size: 0.8rem;
  margin-left: 0.4rem;
  color: var(--color-text-contrast);
  font-weight: bold;
}

.tags {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;

  & > li {
    padding: 1rem 1.25rem;
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    &.active {
      background: var(--color-info);
      color: var(--color-text-contrast);

      & > a {
        color: var(--color-text-contrast);
        border-color: var(--color-text-contrast);
      }
    }

    &:hover:not(.active):not(.noTags) {
      background: rgba(0,0,0, 0.05);
    }

    &.noTags {
      cursor: default;
    }

    &.viewMore {
      font-weight: bold;
      text-align: center;
      display: block;
      border-top: 1px solid var(--color-text-contrast);
    }
  }
}

.tagName {
  font-weight: bold;
}

.loading {
  padding: 1rem;
  align-self: center;
  color: var(--color-meh);
}

.compareLink {
  color: var(--color-text);
  border-bottom: 1px dotted var(--color-text);
  text-decoration: none;

  & > i {
    font-size: 1.2rem;
    vertical-align: top;
    padding-left: 0.2rem;
  }
}
