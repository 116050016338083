.container {
  height: 12rem;
  background-color: rgb(60, 60, 60);
  display: flex;
  margin-bottom: 2rem;

  & > * {
    height: 100%;
    min-height: 100%;
    width: 100%;
  }
}
