.admin {
  display: flex;
  align-items: center;
  height: 3.3rem;
  padding: 0 0.75rem 0 1rem;
  margin: 0.5rem;
  background: var(--color-gray-5);
  border-radius: 8px;

  & .activeIndicator {
    width: 1.75rem;
    display: flex;
    align-items: center;

    & > div {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      border: 1px solid var(--color-gray-2);


      &.active {
        background: var(--color-success);
        border: none;
      }
    }
  }

  & .name {
    font-size: 1.2rem;

    & > * {
      vertical-align: middle;
    }
  }

  & .email {
    color: var(--color-meh);
  }
}

@media (prefers-color-scheme: dark) {
  .admin {
    background: var(--color-gray-4);
  }
}

.slackImage {
  width: 1.25rem;
  margin-left: 0.5rem;
}
