:root {
  --dropdown-value-border-size: calc(var(--input-field-height) / 7);
  --dropdown-color-white: var(--color-white);
  --dropdown-color-primary: var(--color-dodger-blue);
  --dropdown-color-primary-contrast: var(--color-primary-contrast);
  --dropdown-color-disabled: rgba(0, 0, 0, 0.26);
  --dropdown-value-hover-background: var(--color-gray-5);
  --dropdown-overflow-max-height: 45vh;
  --dropdown-value-border-radius: calc(0.2 * var(--unit));
}
