.deploymentSummary {
  text-align: left;
}

.projectDetails {
  margin-bottom: 15px;
}

.projectDetails h2 {
  margin: 0;
}

.envName {
  display: inline-block;
}

.editButton {
  display: inline-block;
  margin-left: 10px;
}

.editButton i {
  color: var(--color-text);
  font-size: 18px;
  vertical-align: bottom;
}

.rotate {
  animation: rotate 1s linear 0s infinite;
}

@keyframes rotate {
  to {
    transform: rotateZ(180deg);
  }
}

.logToggle {
  cursor: pointer;
}

.log {
  list-style: none;
  padding: 0;
  margin: 0 0 1rem 0;

  & > li {
    display: flex;
    justify-content: center;

    & > div {
      padding-top: 1rem;
    }

    & > .leftSide {
      width: 25%;
      padding-right: 1rem;
      margin-right: 1rem;
      text-align: right;
      display: flex;
      flex-direction: column;
      border-right: 5px solid var(--color-meh);
      overflow: hidden;

      &.success {
        border-right-color: var(--color-success);
      }

      &.running {
        border-right-color: var(--color-info);
      }

      &.error {
        border-right-color: var(--color-danger);
      }

      &.cancel {
        border-right-color: var(--color-gray-1);
      }

      & > .artifactName {
        font-size: 1.25rem;
        max-width: 100%;
      }

      & > .time {
        color: var(--color-meh);
      }
    }

    & > .details {
      display: flex;
      flex-wrap: wrap;
      width: 75%;
      margin-top: 1rem;

      & > .deploymentLogWrap {
        flex-basis: 100%;
      }

      & > div {
        flex-basis: 20%;
        padding-bottom: 1rem;

        & > label {
          text-transform: uppercase;
          color: var(--color-meh);
          display: block;
          font-size: 0.8rem;
          letter-spacing: 0.05rem;
          margin-bottom: 0.5rem;
          user-select: none;
        }
      }
    }
  }
}

.deploymentLogWrap {
  & > button {
    & > i {
      vertical-align: middle;
      font-size: 1.2rem;
    }
  }

  & > .deploymentLog {
    height: 0;
    overflow-y: hidden;

    &.open {
      height: 100%;
      margin-top: 1rem;
    }
  }
}

.status {
  border-radius: 0.25rem;
  padding: 0 0.75rem;
  display: inline-block;

  & > * {
    vertical-align: middle;
    line-height: 1.5;
  }

  & > span {
    margin-right: 0.3rem;
  }

  & > i {
    font-size: 1rem;
    margin-right: 0.2rem;
  }

  &.success {
    color: var(--color-success);
    border: 1px solid var(--color-success);
    background: rgba(35, 209, 96, 0.015);
  }

  &.running {
    color: var(--color-info);
    border: 1px solid var(--color-info);
    background: rgba(0, 158, 235, 0.015);
  }

  &.error {
    color: var(--color-danger);
    border: 1px solid var(--color-danger);
    background: rgba(208, 1, 27, 0.015);
  }

  &.cancel {
    color: var(--color-gray-1);
    border: 1px solid var(--color-gray-1);
    background: rgba(0, 158, 235, 0.015); /* todo: figure out what this should be */
  }
}

.cancelButton {
  margin-top: 0.2rem;
  padding: 0 0.75rem !important;
}

.heading {
  display: flex;
  justify-content: space-between;
}

.pagination {
  display: flex;
  justify-content: flex-end;

  & > div {
    background: #e9ecef;
    border-radius: 0.2rem;
    padding: 0.2rem;
  }
}

.noDeployments {
  font-size: 1.5rem;
  text-align: center;
  color: var(--color-meh);
}
