.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text);
  font-weight: 500;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;

  & > .name {
    flex: 1;
  }

  &.passing {
    color: var(--color-text-contrast);
    background-color: var(--color-success);
  }

  &.failed {
    color: var(--color-text-contrast);
    background-color: #bd4f47;
  }

  &.canceled {
    color: var(--color-text-contrast);
    background-color: var(--color-gray-1);
  }

  &.deploying {
    color: var(--color-text-contrast);
    background: var(--color-info);
    position: relative;
    overflow: hidden;

    &:after {
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2));
      content: '';
      display: block;
      height: 100%;
      position: absolute;
      width: 200px;
      left: 0;
      animation: loading 2.5s infinite ease-in;
    }

    &.canceling {
      color: var(--color-text-contrast);
      background-color: var(--color-gray-1);
    }
  }
}

.artifactNameWrapper {
  display: flex;

  & > button {
    background: none;
    border: none;
    padding: 0;
    color: var(--color-text);
    border-bottom: 1px dotted var(--color-text);
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;

    & > i {
      font-size: 1.2rem;
      vertical-align: top;
      padding-left: 0.2rem;
    }
  }
}

.moveButton {
  cursor: all-scroll;
  font-size: 1.2rem;
}

.actions {
  flex-grow: 0;

  & > i {
    font-size: 1.2rem;
    cursor: pointer;
    margin-right: 0.2rem;

    &:last-child {
      margin: 0;
    }
  }

  & .archiveButton {
    color: inherit;
    text-shadow: inherit;

    & > i {
      font-size: 1.2rem;
    }
  }
}

.envDetails {
  padding: 0.5rem;

  & > div {
    font-size: 0.85rem;
    padding: 1rem;
    overflow: hidden;

    & > label {
      font-size: 0.8rem;
      display: block;
      margin-bottom: 0.3rem;
      font-weight: 500;
    }
  }
}

.foot {
  display: flex;
  margin-top: auto;
  border-top: var(--card-border);

  & > button {
    line-height: 1.75;
    flex: 1;
    border-radius: 0 0 var(--card-border-radius) var(--card-border-radius);

    & > i {
      font-size: 1.2rem;
    }

    &:not(:only-child) {
      border-radius: 0;

      &:first-child {
        border-radius: 0 0 0 var(--card-border-radius);
      }
      &:last-child {
        border-radius: 0 0 var(--card-border-radius) 0;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 20.5rem;
  border: 0;
  box-shadow: var(--zdepth-shadow-1);
}

.content {
  padding: 1rem;
}

.noDeployments {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.providerMissing {
  margin: 1rem 1rem 0;
  text-align: center;
  vertical-align: middle;

  & > button {
    margin: 0.5rem auto 0;
    display: block;
  }

  & a {
    text-decoration: none;
    color: var(--color-text);
    font-weight: bold;
  }
}

@keyframes loading {
  from {
    left: -200px;
  }
  to {
    left: 100%;
  }
}

@media (prefers-color-scheme: dark) {
  .container {
    border: var(--card-border);
    background: var(--color-gray-4);
  }
}

.compareButton {
  margin-left: 12px;
  min-width: 0;
  padding: 2px 8px;
  height: 24px;
  text-transform: none;
  vertical-align: middle;
}

.artifactNameContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.artifactNameLabel {
  font-weight: 500;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.7);
}

.artifactName {
  margin-right: 8px;
  word-break: break-all;
  font-family: 'Roboto Mono', monospace;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 2px 4px;
  border-radius: 4px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}