:root {
  --footer-background: var(--color-rhino);
}

@media (prefers-color-scheme: dark) {
  :root {
    --footer-background: var(--color-gray-5);
  }
}


.footerWrap {
  margin-top: 2rem;

  & > .tip {
    text-align: center;
    font-size: 0.9rem;
    margin: 0 0 1rem;
    color: var(--color-gray-1);

    & code {
      border: 1px solid var(--chip-border);
      padding: 0.1em 0.3em;
      border-radius: 0.25em;
      background: var(--chip-background);
    }
  }

  & > footer {
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    background: var(--footer-background);

    & .shieldLogo {
      height: 2rem;
      filter: grayscale(0.5);

      &:hover {
        filter: grayscale(0);
      }
    }

    & > .container {
      display: flex;
      align-items: center;

      & > * {
        flex: 1;
        display: flex;
        justify-content: center;

        &:first-child {
          justify-content: flex-start;
        }

        &:last-child {
          justify-content: flex-end;
        }
      }

      & .logoLink {
        color: #b8c9e6;
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
          color: #fff;
        }

        & > img {
          height: 1.25rem;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
