.sectionLabel {
  composes: sidebarLabel from '~style/helpers.module.css';
  margin-top: 0.5rem;
}

.envLinks {
  padding: 0;
  margin: 0;
  & li {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    position: relative;
    & .linkName > a {
      color: var(--color-text);
      text-decoration: none;
      & > i {
        margin-right: 0.5rem;
      }
      & > * {
        vertical-align: middle;
      }
    }
    & .actions {
      & > a > i, & > i, & > button i {
        font-size: 1.2rem;
        margin-right: 0.25rem;
      }
    }
    &:hover {
      background: rgba(0,0,0, 0.05);

      & > div:first-of-type:not(:only-child) {
        margin-right: 3.25rem;
      }
    }
  }
}

.edit {
  color: var(--color-info);
  cursor: pointer;
}

.delete {
  color: var(--color-danger);
  cursor: pointer;
}

.sectionLabel i {
  color: var(--color-info);
  font-size: 0.8rem;
  margin: 0 0 0 .25rem;
}

.add {
  cursor: pointer;
  color: var(--color-meh);
  user-select: none;
  font-size: 0.8rem;
  margin: 0.5rem 0 0 1rem;
}

.add > * {
  vertical-align: middle;
}

.add > i {
  color: var(--color-info);
  font-size: 1.2rem;
}
