@import './config.module.css';

.pagination {

}

.dropdown {
  display: inline-block;
  width: 7em;
}

.resultInfo {
  display: inline-block;
  width: 150px;
  text-align: center;
  font-size: .85em;
}

.button {
  display: inline-block;
  border: 0;
  padding: 0 0.75em;
  opacity: var(--page-button-opacity);
  cursor: pointer;
  vertical-align: middle
}

.button:disabled {
    opacity: var(--page-button-disabled-opacity);
  }
