@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.chip {
  background-color: var(--chip-background);
  border-radius: calc(0.4 * var(--unit));
  border: 1px solid var(--chip-border);
  color: var(--chip-color);
  composes: reset from '../helpers.module.css';
  display: inline-block;
  font-size: var(--chip-font-size);
  line-height: var(--chip-height);
  margin-right: var(--chip-margin-right);
  max-width: 100%;
  overflow: hidden;
  padding: 0 var(--chip-padding);
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.avatar {
  padding-left: 0
}
.avatar > [data-react-toolbox='avatar'] {
    height: var(--chip-height);
    margin-right: var(--chip-icon-margin-right);
    vertical-align: middle;
    width: var(--chip-height)
  }
.avatar > [data-react-toolbox='avatar'] > span {
      font-size: var(--chip-icon-font-size);
      line-height: var(--chip-height);
    }
.deletable {
  padding-right: calc(var(--chip-remove-size) + var(--chip-remove-margin));
}
.delete {
  cursor: pointer;
  display: inline-block;
  height: var(--chip-remove-size);
  margin: var(--chip-remove-margin) 2px 0;
  padding: var(--chip-remove-margin);
  position: absolute;
  right: 0;
  width: var(--chip-remove-size);
}
.delete:hover .deleteIcon {
  background: var(--chip-remove-background-hover);
}
.deleteIcon {
  background: var(--chip-remove-background);
  border-radius: var(--chip-remove-size);
  vertical-align: top
}
.deleteIcon .deleteX {
    fill: transparent;
    stroke: var(--chip-remove-color);
    stroke-width: var(--chip-remove-stroke-width);
  }
