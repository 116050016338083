.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
}

.mainCard {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  border-radius: 8px;
  overflow: hidden;
}

.cardContent {
  padding: 24px;
}

.cardColumn {
  padding: 0 12px;
}

.innerCard {
  height: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #e6e6e6;
}

.rightColumnCards {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 24px;
}

.secondCard {
  margin-top: auto;
}

/* Add a responsive adjustment for mobile */
@media (max-width: 768px) {
  .cardColumn {
    margin-bottom: 24px;
  }
  
  .rightColumnCards {
    gap: 24px;
  }
}