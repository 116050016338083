
@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.iconContainer {
  position: relative;
  display: inline-block;
}

.iconMenu {
  display: inline-block;
  position: relative;
  text-align: center;
  min-height: 24px;
  min-width: 24px;
  composes: reset from '../helpers.module.css'
}

.iconMenu .icon {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

.iconDesktopContainer {
  display: block;
}

.iconMobileContainer {
  display: none;
}

.menu {
  height: 100%;
  display: inline-block;
  position: relative
}

.menu.topLeft {
    left: -1rem;
    top: 2.5rem;
    position: absolute
  }

.menu.topLeft > .outline {
      transform-origin: 0 0;
      box-shadow: var(--shadow-2p)
    }

.menu.topLeft > .outline:before {
        transform: rotate(45deg);
        left: 0.5rem;
        top: -5px;
      }

.menu.topRight {
    position: absolute;
    right: -0.5rem;
    top: 2.5rem
  }

.menu.topRight > .outline {
      transform-origin: 100% 0;
      box-shadow: var(--shadow-2p)
    }

.menu.topRight > .outline:before {
        transform: rotate(45deg);
        right: 0.5rem;
        top: -5px;
      }

.menu.bottomLeft {
    bottom: 2.5rem;
    left: -0.5rem;
    position: absolute
  }

.menu.bottomLeft > .outline {
      transform-origin: 0 100%;
      box-shadow: var(--shadow-2p-up)
    }

.menu.bottomLeft > .outline:before {
        transform: rotate(225deg);
        left: 0.5rem;
        bottom: -5px;
      }

.menu.bottomRight {
    position: absolute;
    bottom: 2.5rem;
    right: -0.5rem
  }

.menu.bottomRight > .outline {
      transform-origin: 100% 100%;
      box-shadow: var(--shadow-2p-up)
    }

.menu.bottomRight > .outline:before {
        transform: rotate(225deg);
        right: 0.5rem;
        bottom: -5px;
      }

.menu:not(.static) {
    pointer-events: none;
    z-index: var(--z-index-higher)
  }

.menu:not(.static) > .outline {
      opacity: 0;
      transition:
              opacity var(--menu-fade-duration) var(--animation-curve-default);
      will-change: transform;
    }

.menu:not(.static) > .menuInner {
      margin: 0;
      opacity: 0
    }

.menu:not(.static) > .menuInner > .menuDivider {
        position: relative;
        background-color: var(--color-gray-5);
        border: 0;
        display: block;
        height: 1px;
        margin: 0.5rem 0;
        outline: 0;
        padding: 0;
        width: 100%;
      }

.menu:not(.static).active {
      pointer-events: all
    }

.menu:not(.static).active > .outline {
        opacity: 1;
      }

.menu:not(.static).active > .menuInner {
        opacity: 1;
        transition: opacity var(--menu-fade-duration) var(--animation-curve-default);
      }

.outline {
  background: var(--menu-background);
  border-radius: 8px;
  display: block;
  left: 0;
  position: absolute;
  top: 0
}

.outline:before {
    content: '';
    display: block;
    height: 1.5rem;
    width: 1.5rem;
    background: var(--menu-background);
    position: absolute;
    border-radius: 5px;
    -webkit-clip-path: polygon(0 60%, 0 0, 60% 0);
            clip-path: polygon(0 60%, 0 0, 60% 0);
  }

.menuInner {
  display: block;
  list-style: none;
  padding: var(--menu-padding);
  text-align: left;
  white-space: nowrap;
}

.menuItem {
  composes: reset from '../helpers.module.css';

  font-family: var(--primary-font);
  align-items: center;
  color: var(--menu-item-color);
  display: flex;
  font-size: 1rem;
  height: var(--menu-item-height);
  overflow: hidden;
  padding: 0;
  position: relative
}

.menuItem:not(.disabled):hover {
    background: var(--menu-item-background-hover);
    cursor: pointer;
  }

.menuItem.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

.menuItem.selected {
    background-color: var(--menu-item-selected-background);
    font-weight: 500;
  }

.menuItem .icon {
    margin-left: 1rem;
  }

.menuItem > a {
    display: flex;
    color: inherit;
    text-decoration: none;
    width: 100%;
    height: 100%;
    align-items: center;
  }

.menuItem .caption {
    flex-grow: 1;
    padding: 0 var(--menu-item-padding);
  }

.menuItem .shortcut {
    margin-left: var(--menu-item-padding);
  }


@media (max-width: 600px) {
  .iconDesktopContainer {
    display: none;
  }

  .iconMobileContainer {
    display: block;
  }
}
