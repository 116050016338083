.actions {
  display: flex;
  align-items: center;
}

.title {
  margin-bottom: 0;
}

.parentGroupName {
  &,
  & i {
    font-size: 1.1rem;
  }

  & a {
    color: var(--color-info);
    font-weight: bold;
    text-decoration: none;
  }
}
