@import '../colors.module.css';
@import '../variables.module.css';

:root {
  --button-border-radius: 0.25rem;
  --button-color-primary: var(--color-info);
  --button-color-primary-hover: hsl(209.51351351351352, 100%, 49%);
  --button-color-primary-active: rgb(16, 101, 183);
  --button-color-success: var(--color-success);
  --button-color-success-hover: hsl(156.4485981308411, 100%, 57.8235294118%);
  --button-color-success-active: rgb(74, 170, 132);
  --button-color-warning: var(--color-warning);
  --button-color-warning-hover: hsl(53.47826086956522, 100%, 82%);
  --button-color-warning-active: rgb(225, 216, 142);
  --button-color-danger: var(--color-danger);
  --button-color-danger-hover: hsl(3.8961038961038974, 100%, 55.1764705882%);
  --button-color-danger-active: rgb(185, 55, 46);
}


.iconRight,
.iconLeft {
  font-size: 1.1em;
  vertical-align: text-bottom;
  text-decoration: none;
}

.iconLeft {
  margin-right: 0.3rem;
}

.iconRight {
  margin-left: 0.3rem;
}


.button {
  composes: reset from '../helpers.module.css';
  display: inline flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  border: 1px solid;
  border-radius: 0.25rem;
  border-radius: var(--button-border-radius);
  outline: none;
  font-size: 15px;
  padding: .7rem 1.5rem;
  cursor: pointer;
  font-family: var(--primary-font);
  transition: color var(--default-transition), background-color var(--default-transition), border-color var(--default-transition)
}


.button.fullWidth {
    width: 100%;
  }


.button.large {
    padding: 0.625rem 1.25rem;
    font-size: 20px;
  }


.button.small {
    padding: 0.25rem 1rem;
    font-size: 13px;
  }


.button.primary {
    background-color: var(--color-info);
    background-color: var(--button-color-primary);
    border-color: var(--color-info);
    border-color: var(--button-color-primary)
  }


.button.primary:hover, .button.primary:focus {
      background-color: hsl(209.51351351351352, 100%, 49%);
      background-color: var(--button-color-primary-hover);
      border-color: hsl(209.51351351351352, 100%, 49%);
      border-color: var(--button-color-primary-hover);
    }


.button.primary:active {
      background-color: rgb(16, 101, 183);
      background-color: var(--button-color-primary-active);
      border-color: rgb(16, 101, 183);
      border-color: var(--button-color-primary-active);
    }


.button.success {
    background-color: var(--color-success);
    background-color: var(--button-color-success);
    border-color: var(--color-success);
    border-color: var(--button-color-success)
  }


.button.success:hover, .button.success:focus {
      background-color: hsl(156.4485981308411, 100%, 57.8235294118%);
      background-color: var(--button-color-success-hover);
      border-color: hsl(156.4485981308411, 100%, 57.8235294118%);
      border-color: var(--button-color-success-hover);
    }


.button.success:active {
      background-color: rgb(74, 170, 132);
      background-color: var(--button-color-success-active);
      border-color: rgb(74, 170, 132);
      border-color: var(--button-color-success-active);
    }


.button.warning {
    background-color: var(--color-warning);
    background-color: var(--button-color-warning);
    border-color: var(--color-warning);
    border-color: var(--button-color-warning)
  }


.button.warning:hover, .button.warning:focus {
      background-color: hsl(53.47826086956522, 100%, 82%);
      background-color: var(--button-color-warning-hover);
      border-color: hsl(53.47826086956522, 100%, 82%);
      border-color: var(--button-color-warning-hover);
    }


.button.warning:active {
      background-color: rgb(225, 216, 142);
      background-color: var(--button-color-warning-active);
      border-color: rgb(225, 216, 142);
      border-color: var(--button-color-warning-active);
    }


.button.danger {
    background-color: var(--color-danger);
    background-color: var(--button-color-danger);
    border-color: var(--color-danger);
    border-color: var(--button-color-danger)
  }


.button.danger:hover, .button.danger:focus {
      background-color: hsl(3.8961038961038974, 100%, 55.1764705882%);
      background-color: var(--button-color-danger-hover);
      border-color: hsl(3.8961038961038974, 100%, 55.1764705882%);
      border-color: var(--button-color-danger-hover);
    }


.button.danger:active {
      background-color: rgb(185, 55, 46);
      background-color: var(--button-color-danger-active);
      border-color: rgb(185, 55, 46);
      border-color: var(--button-color-danger-active);
    }


.button.raised {
    color: #fff;
  }


.button.lowered {
    background-color: transparent
  }


.button.lowered.primary {
      color: var(--color-info);
      color: var(--button-color-primary);
    }


.button.lowered.success {
      color: var(--color-success);
      color: var(--button-color-success);
    }


.button.lowered.warning {
      color: var(--color-warning);
      color: var(--button-color-warning);
    }


.button.lowered.danger {
      color: var(--color-danger);
      color: var(--button-color-danger);
    }


.button.lowered:hover:not([disabled]), .button.lowered:focus:not([disabled]) {
      color: #fff;
    }


.button.flat {
    min-width: 100px;
    background-color: transparent;
    border: none;
    font-weight: 500
  }


.button.flat.primary {
      color: var(--color-info);
      color: var(--button-color-primary);
    }


.button.flat.success {
      color: var(--color-success);
      color: var(--button-color-success);
    }


.button.flat.warning {
      color: var(--color-warning);
      color: var(--button-color-warning);
    }


.button.flat.danger {
      color: var(--color-danger);
      color: var(--button-color-danger);
    }


.button.flat:hover:not([disabled]), .button.flat:focus:not([disabled]) {
      background-color: transparent;
      border: none
    }


.button.flat:hover:not([disabled]) > span, .button.flat:focus:not([disabled]) > span {
        text-decoration: underline;
      }


.button:disabled {
    cursor: default
  }


.button:disabled.raised {
      background-color: var(--color-gray-1);
      color: var(--color-gray-3);
      border: solid 1px var(--color-gray-2)
    }


.button:disabled.raised:hover, .button:disabled.raised:focus {
        background-color: var(--color-gray-1);
        border: solid 1px var(--color-gray-2);
      }


.button:disabled.raised:active {
        background-color: var(--color-gray-1);
        border: solid 1px var(--color-gray-2);
      }


.button:disabled.lowered {
      background-color: transparent;
      color: #ccc;
      border: solid 1px var(--color-gray-2);
    }


.button:disabled.flat {
      background-color: transparent;
      color: #ccc;
    }

@media (prefers-color-scheme: dark) {
      .button.flat.primary {
        color: var(--color-text);
      }
}
