.actions > button {
  margin: 0 0.6rem 0 0;
  padding: 0 !important;
  min-width: auto !important;

  &:last-child {
    margin: 0;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title, .search {
  flex: 1 1;
}

.topActions {
  margin-left: 4rem;
  text-align: right;
}

.modalBody {
  overflow: visible;
}

.role {
  font-weight: 500;
  border-bottom: 1px dotted var(--color-meh);
}

.note {
  text-align: center;
  font-size: 0.8rem;
  margin: 0 0 1rem;
  color: var(--color-gray-1);
}
