.actions > button + button {
  margin-left: 0.5rem;
}

.externalId {
  font-size: 0.7rem;
  padding-top: 0.3rem;
  line-height: 1.1;
  display: block;
  font-style: italic;
}
