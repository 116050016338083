@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.radio {
  border: calc(0.1 * var(--unit)) solid var(--radio-border-color);
  border-radius: 50%;
  composes: reset from '../helpers.module.css';
  cursor: pointer;
  display: inline-block;
  height: var(--radio-button-size);
  position: relative;
  vertical-align: top;
  width: var(--radio-button-size)
}

.radio:hover {
    border-color: var(--color-denim);
  }

.radio::before {
    background-color: var(--radio-inner-color);
    border-radius: 50%;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(0);
    width: 100%;
  }
.radioChecked {
  border: calc(0.1 * var(--unit)) solid var(--radio-inner-color);
  composes: radio
}
.radioChecked::before {
    transform: scale(0.65);
  }
.field {
  display: block;
  height: var(--radio-button-size);
  margin-bottom: var(--radio-field-margin-bottom);
  position: relative;
  white-space: nowrap;
}
.text {
  color: var(--radio-text-color);
  display: inline-block;
  font-size: var(--radio-text-font-size);
  line-height: var(--radio-button-size);
  padding-left: 5px;
  padding-right: 10px;
  vertical-align: top;
  white-space: normal;
  font-family: var(--secondary-font);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: absolute;
}
.input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 0
}
.input:focus ~ .radio {
    box-shadow: 0 0 0 calc(0.2 * var(--unit)) var(--radio-focus-color);
  }
.input:focus ~ .radioChecked {
    box-shadow: 0 0 0 calc(0.2 * var(--unit)) var(--radio-checked-focus-color);
  }
.disabled {
  composes: field
}
.disabled .text {
    color: var(--radio-text-color);
  }
.disabled .radio {
    border-color: var(--color-gray-2);
    background-color: var(--color-gray-1);
    cursor: auto;
  }
.disabled .radioChecked {
    border-color: var(--color-gray-2);
    background-color: var(--color-gray-1);
    cursor: auto
  }
.disabled .radioChecked::before {
      background-color: var(--color-gray-2);
    }
