@import '../variables.module.css';
@import '../colors.module.css';
@import './config.module.css';

.table {
  width: 100%;
  border: var(--table-border);
  border-collapse: collapse;
  background: var(--table-background)
}

.table > thead > tr > th,
  .table > tbody > tr > td {
    border-top: var(--table-border);
    border-bottom: var(--table-border);
  }

.table > thead > tr > th {
    color: var(--table-head-color);
    font-weight: bold;
    padding: 1rem;
    text-align: left;
    line-height: 1.5
  }

.table > thead > tr > th.sortable {
      cursor: pointer
    }

.table > thead > tr > th.sortable:not(.active) {
        padding-right: 2.5rem;
      }

.table > thead > tr > th.sortable .sortIcon {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        vertical-align: middle;
        font-size: 1.5rem;
      }

.table > tfoot > tr > td {
    text-align: right;
  }

.table.zebra > tbody > tr:nth-child(odd) {
      background-color: var(--table-zebra-background);
    }

.table > tbody > tr > td {
    padding: 0.5rem 0.625rem;
    font-size: 0.85rem;
    word-break: break-all;
  }
