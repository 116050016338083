:root {
  --color-black: rgb(0, 0, 0);
  --color-white: rgb(255, 255, 255);

  --color-big-stone: rgb(20, 33, 50);
  --color-rhino: rgb(38, 54, 82);
  --color-san-juan: rgb(53, 77, 117);
  --color-denim: rgb(18, 112, 203);
  --color-dodger-blue: rgb(44, 185, 255);
  --color-peri-gray: rgb(184, 201, 229);
  --color-foam: rgb(234, 245, 253);
  --color-persian-red: rgb(205, 61, 51);
  --color-puerto-rico: rgb(50, 128, 97);
  --color-texas: rgb(250, 240, 158);

  --color-gray-1: rgb(115, 115, 119);
  --color-gray-2: rgb(174, 174, 178);
  --color-gray-3: rgb(199, 199, 204);
  --color-gray-4: rgb(209, 209, 214);
  --color-gray-5: rgb(229, 229, 234);
  --color-gray-6: rgb(242, 242, 247);

  --color-danger: var(--color-persian-red);
  --color-success: var(--color-puerto-rico);
  --color-warning: var(--color-texas);
  --color-info: var(--color-denim);

  --color-text: var(--color-big-stone);
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-big-stone: rgb(30, 43, 60);
    --color-rhino: rgb(48, 64, 92);
    --color-san-juan: rgb(63, 87, 127);
    --color-denim: rgb(28, 122, 213);
    --color-dodger-blue: rgb(54, 195, 255);
    --color-peri-gray: rgb(194, 211, 239);
    --color-foam: rgb(244, 255, 255);

    --color-persian-red: rgb(255, 71, 61);
    --color-puerto-rico: rgb(92, 199, 157);
    --color-texas: rgb(255, 250, 168);

    --color-gray-1: rgb(142, 142, 147);
    --color-gray-2: rgb(99, 99, 102);
    --color-gray-3: rgb(72, 72, 74);
    --color-gray-4: rgb(58, 58, 60);
    --color-gray-5: rgb(44, 44, 46);
    --color-gray-6: rgb(28, 28, 30);

    --color-text: #e2e2e2;
  }
}
