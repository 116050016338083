.nav {
  height: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  background: var(--nav-background);

  & > a {
    font-size: 0.9rem;
    display: block;
    justify-content: flex-start;
    margin-right: 1.2rem;
    color: var(--color-text-contrast);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
