@import '../colors.module.css';
@import '../variables.module.css';
@import './config.module.css';

.field {
  composes: reset from '../helpers.module.css';
  display: block;
  height: var(--checkbox-size);
  margin-bottom: var(--checkbox-field-margin-bottom);
  position: relative;
  white-space: nowrap;
}
.text {
  color: var(--checkbox-text-color);
  display: inline-block;
  font-size: var(--checkbox-text-font-size);
  line-height: var(--checkbox-size);
  vertical-align: top;
  white-space: normal;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: var(--secondary-font);
  letter-spacing: 0.5px;
  word-wrap: break-word;
  padding-left: 10px;
}
.input {
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 0
}
.input:focus ~ .check {
    box-shadow: 0 0 0 calc(0.2 * var(--unit)) var(--checkbox-focus-color)
  }
.input:focus ~ .check.checked {
      box-shadow: 0 0 0 calc(0.2 * var(--unit)) var(--checkbox-focus-checked-color);
    }
.input:focus ~ .check.checked::before {
      background-color: var(--checkbox-focus-checked-color);
    }
.check {
  border-color: var(--checkbox-border-color);
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  composes: reset from '../helpers.module.css';
  cursor: pointer;
  display: inline-block;
  height: var(--checkbox-size);
  position: relative;
  vertical-align: top;
  width: var(--checkbox-size)
}
.check:hover {
    border-color: var(--checkbox-border-color-hover);
  }
.check.checked {
    background-color: var(--checkbox-color);
    border-color: var(--checkbox-color)
  }
.check.checked::after {
      border-bottom-width: 1px;
      border-color: var(--color-white);
      border-left: 0;
      border-right-width: 1px;
      border-style: solid;
      border-top: 0;
      content: '';
      height: calc(1 * var(--unit));
      left: calc(0.7 * var(--unit));
      position: absolute;
      top: calc(0.3 * var(--unit));
      transform: rotate(45deg);
      width: calc(0.6 * var(--unit));
    }
.disabled > .check {
    background-color: var(--checkbox-disabled-color);
    border-color: var(--checkbox-disabled-border-color);
    cursor: auto
  }
.disabled > .check.checked {
      cursor: auto
    }
.disabled > .check.checked::after {
        border-color: var(--color-gray-3);
      }
