.radioFieldset {
  border: none;
  margin: 0;
  padding: 0;

  & > legend {
    font-size: 13px;
    font-weight: 500;
  }
}

.radios {
  color: var(--color-text);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  flex-wrap: wrap;

  & > label {
    margin-top: 1rem;
    margin-right: 1rem;
    text-transform: initial;

    & > span {
      position: initial;
      max-width: initial;
      text-transform: none;
    }
  }
}
