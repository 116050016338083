:root {
  --alert-text-color: var(--color-text);
  --alert-remove-size: calc(1.9 * var(--unit));
  --alert-remove-margin: 0 calc(0.8 * var(--unit)) calc(0.4 * var(--unit));
  --alert-remove-stroke-width: calc(0.4 * var(--unit));
  --alert-remove-color: var(--color-gray-5);
  --alert-info-background: rgba(18, 112, 203, 0.1);
  --alert-success-background: rgba(82, 189, 147, 0.1);
  --alert-danger-background: rgba(205, 61, 51, 0.1);
  --alert-warning-background: rgba(250, 240, 158, 0.1);
  --alert-info-border: 1px solid var(--color-info);
  --alert-success-border: 1px solid var(--color-success);
  --alert-danger-border: 1px solid var(--color-danger);
  --alert-warning-border: 1px solid var(--color-warning);
}
