@import '../colors.module.css';
@import '../variables.module.css';
@import '../input/config.module.css';
@import './config.module.css';

.inputElementWrap {
  cursor: pointer !important;
}

.dropdown {
  position: relative;
  outline: none;

  composes: reset from '../helpers.module.css'
}

.dropdown:not(.active) > .values {
      max-height: 0;
      visibility: hidden;
    }

.dropdown.active > .value > input {
      background: var(--color-gray-1);
      border-color: var(--color-gray-2);
    }

.dropdown.active > .values {
      max-height: var(--dropdown-overflow-max-height);
      visibility: visible;
    }

.dropdown:not(.up) > .values {
    bottom: auto;
    top: 3.875rem;
  }

.dropdown.up > .values {
    bottom: 2.625rem;
    top: auto;
  }

.dropdown.disabled {
    cursor: normal;
    pointer-events: none;
  }

.field {
  cursor: pointer;
  padding: var(--input-padding) 0;
  position: relative
}

.field.errored {
    padding-bottom: 0
  }

.field.errored > .label {
      color: var(--input-text-error-color);
    }

.field.errored > .templateValue {
      border-bottom: 1px solid var(--input-text-error-color);
    }

.field.errored > .label > .required {
      color: var(--input-text-error-color);
    }

.field.disabled {
    cursor: normal;
    pointer-events: none
  }

.field.disabled > .templateValue {
      border-bottom-style: dotted;
      opacity: 0.7;
    }

.templateValue {
  background-color: var(--input-text-background-color);
  border-bottom: 1px solid var(--input-text-bottom-border-color);
  color: var(--color-text);
  min-height: var(--input-field-height);
  padding: var(--input-field-padding) 0;
  position: relative;
}

.label {
  color: var(--input-text-label-color);
  font-size: var(--input-label-font-size);
  left: 0;
  line-height: var(--input-field-font-size);
  position: absolute;
  top: var(--input-focus-label-top);
  padding: 0 0 0 15px
}

.label .required {
    color: var(--input-text-error-color);
  }

.error {
  color: var(--input-text-error-color);
  font-size: var(--input-label-font-size);
  line-height: var(--input-underline-height);
  margin-bottom: calc(-1 * var(--input-underline-height));
}

.values {
  background: var(--color-gray-6);
  border: 1px solid var(--color-gray-4);
  border-radius: var(--dropdown-value-border-radius);
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  top: 30px;
  transition-duration: var(--animation-duration);
  transition-property: max-height, box-shadow;
  transition-timing-function: var(--animation-curve-default);
  width: 100%;
  z-index: var(--z-index-high)
}

.values > * {
    cursor: pointer;
    overflow: hidden;
    padding: var(--unit);
    position: relative;
    color: var(--color-text)
  }

.values > *:hover:not(.disabled) {
      background-color: var(--dropdown-value-hover-background);
    }

.values > *.selected {
      color: var(--dropdown-color-primary);
    }

.values > *.disabled {
      color: var(--dropdown-color-disabled);
      cursor: not-allowed;
    }

.values::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
