.text {
  text-transform: capitalize;
}

.label {
  pointer-events: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 0.5em;
  color: var(--input-text-label-color);
}

.error {
  color: var(--input-text-error-color);
  font-size: var(--input-label-font-size);
  line-height: var(--input-underline-height);
  margin-bottom: calc(-1 * var(--input-underline-height));
  margin-top: 5px;
}

.select {
  margin-bottom: 1rem;
}

.inputDisabled {
  color: var(--color-gray-1);
  cursor: not-allowed;
}
