@import '../variables.module.css';
@import './config.module.css';
@import '../colors.module.css';


.alert {
  composes: reset from '../helpers.module.css';

  border-radius: calc(0.4 * var(--unit));
  padding: calc(1.5 * var(--unit)) calc(2 * var(--unit));
  margin: 0 0 var(--unit);
  position: relative;
  color: var(--alert-text-color);
  font-size: 1rem;
  line-height: 1.53;
  border: 1px solid transparent
}


.alert.success {
    background: var(--alert-success-background);
    border: var(--alert-success-border);
  }


.alert.info {
    background: var(--alert-info-border);
    border: var(--alert-info-border);
  }


.alert.warning {
    background: var(--alert-warning-background);
    border: var(--alert-warning-border);
  }


.alert.danger {
    background: var(--alert-danger-background);
    border: var(--alert-danger-border);
  }

.deletable {
  padding-right: 0;
}

.delete {
  cursor: pointer;
  display: inline-block;
  height: var(--alert-remove-size);
  margin: var(--alert-remove-margin);
  padding: var(--alert-remove-margin);
  position: absolute;
  right: 0;
  width: var(--alert-remove-size);
}


.deleteIcon {
  vertical-align: top
}


.deleteIcon .deleteX {
    fill: transparent;
    stroke: var(--alert-remove-color);
    stroke-width: var(--alert-remove-stroke-width);
  }
