:root {
  --dialog-max-height: calc(59.7 * var(--unit));
  --dialog-max-width: calc(72 * var(--unit));
  --dialog-border-radius: 0.5rem;
  --dialog-color-title: var(--color-text);
  --dialog-background: var(--color-white);
  --dialog-content-padding: calc(4 * var(--unit));
  --dialog-content-bottom-padding: calc(2 * var(--unit));
  --dialog-navigation-padding: calc(3 * var(--unit));
  --dialog-translate-y: calc(4 * var(--unit));
  --dialog-button-width: calc(14 * var(--unit));
  --zdepth-shadow-modal: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);

}

@media (prefers-color-scheme: dark) {
  :root {
    --dialog-background: var(--color-gray-5);
  }
}
