:root {
    --chip-height: calc(3 * var(--unit));
    --chip-padding: calc(0.8 * var(--unit));
    --chip-margin-right: calc(0.8 * var(--unit));
    --chip-background: rgb(247, 249, 250);
    --chip-border: var(--color-gray-3);
    --chip-icon-font-size: calc(2 * var(--unit));
    --chip-icon-margin-right: calc(0.8 * var(--unit));
    --chip-color: var(--color-rhino);
    --chip-font-size: calc(1.3 * var(--unit));
    --chip-remove-size: calc(2.4 * var(--unit));
    --chip-remove-margin: calc(0.4 * var(--unit));
    --chip-remove-stroke-width: calc(0.2 * var(--unit));
    --chip-remove-background: transparent;
    --chip-remove-background-hover: transparent;
    --chip-remove-color: var(--color-gray-4);
}

@media (prefers-color-scheme: dark) {
    :root {
        --chip-color: var(--color-text);
        --chip-background: var(--color-gray-5);
    }
}
