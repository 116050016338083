:root {
    --table-border: 1px solid var(--color-gray-5);
    --table-head-color: var(--color-text);
    --table-background: var(--color-white);
    --table-zebra-background: var(--color-gray-6);
}

@media (prefers-color-scheme: dark) {
    :root {
        --table-border: 1px solid var(--color-gray-3);
        --table-background: var(--color-gray-4);
        --table-zebra-background: var(--color-gray-3);
    }
}
