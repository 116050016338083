.envSummary {
  margin-bottom: 1rem;
  padding: 12px;
}

.head {
  display: flex;
  align-items: center;

  & .breadcrumbs {
    flex: 1;
  }

  & .actions {

  }
}

.noEnvironments {
  font-size: 1.5rem;
  text-align: center;
  flex: 1;
  color: var(--color-meh);
  margin: 3rem 0rem 3rem 0rem;
}